
import {
  useRouter
} from 'vue-router'
import {
  useStore
} from 'vuex'
import { environment } from '@/utils'

export default {
  setup() {
    const router = useRouter()
    const store = useStore()
    const backToHome = async () => {
      if (store.state.token.idToken) {
        window.location.href = await `${environment.getHydraApi()}oauth2/sessions/logout?id_token_hint=${store.state.token.idToken}&post_logout_redirect_uri=${window.location.origin}`
        store.dispatch('logoutAccount')
      } else {
        store.dispatch('logoutAccount')
        router.push({
          name: 'home'
        })
      }
    }
    return {
      backToHome
    }
  }
}
